/** @format */

import { React, useState } from "react";
import { useDispatch } from "react-redux";
import { Typography, Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import CustomCheckbox from "../../../components/customCheckbox";
import { collectionLocationChosen } from "../../../redux/reducers/cart";

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  textStyle: { ...theme.textStyle, color: "#fd7762", fontWeight: "bold" },
}));

export default function PickupLocation() {
  const classes = useStyles();
  const dispatch = useDispatch();

  let locationsInfo = [
    {
      displayName: "1483 Topsail Rd., Paradise",
      name: "1483 Topsail Rd.",
      size: "20",
      borderColor: "#e4e4e7",
      checkColor: "#fd7762",
      checked: false,
    },
    {
      displayName: "16 Stavanger Dr., St. John's",
      name: "16 Stavanger Dr.",
      size: "20",
      borderColor: "#e4e4e7",
      checkColor: "#fd7762",
      checked: false,
    },
  ];

  const [locationCheckboxes, setLocationCheckboxes] = useState(locationsInfo);

  const handleChange = (locationId) => {
    const selectedLocation = locationsInfo.find((l) => l.name === locationId);
    if (selectedLocation) {
      dispatch(collectionLocationChosen(selectedLocation.name));
    } else {
      console.log(`a location with the locatio id ${locationId} was not found`);
    }
    setLocationCheckboxes((prevLocationCheckboxes) =>
      prevLocationCheckboxes.map((location) => {
        return location.name === locationId
          ? { ...location, checked: true }
          : { ...location, checked: false };
      })
    );
  };

  return (
    <Grid container sx={{ mb: 3 }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Box className={classes.titleWrapper}>
          <LocationOnOutlinedIcon
            style={{ color: "#fd7762", marginRight: "10px" }}
          />
          <Typography className={classes.textStyle}>Select Location</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {locationCheckboxes.map((l) => {
          return (
            <CustomCheckbox
              optionName={l.name}
              optionDisplayName={l.displayName}
              size={l.size}
              borderColor={l.borderColor}
              checkedColor={l.checkColor}
              checkedStatus={l.checked}
              handleCheckboxChange={handleChange}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
