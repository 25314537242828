/** @format */

import { React, useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import {
  Grid,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginTop: "2rem !important",
    marginBottom: "1rem !important",
    padding: "15px 10px",
  },
  sectionTitle: {
    color: theme.palette.secondary.dark,
    marginBottom: "20px !important",
    textAlign: "center",
    ...theme.textStyle,
  },
  sectionSubTitle: {
    color: theme.palette.secondary.main,
    textAlign: "center",
    marginBottom: "40px !important",
    ...theme.textStyle,
  },
  steps: {
    color: theme.palette.secondary.main,
  },
  proceedToPaymentBtn: {
    ...theme.containedButtonDark,
    width: "100%",
  },
  textStyle: { ...theme.textStyle },
}));

function Summary(props) {
  const classes = useStyles();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [enablePayBtn, setEnablePayBtn] = useState(true);
  let registeredUserFullName = null;
  const timer = useRef();

  const {
    products,
    addOns,
    collection,
    coupon,
    additionalNotes,
    guestCustomerInfo,
  } = props;

  const taxAmount = 0;
  let subtotalAmount = 0;
  let discountAmount = coupon ? coupon.appliedAmount : 0;
  let totalAmount = 0;

  // for graze boxes bundle save discount
  let totalNumberOfGrazeBoxesInTheCart = 0;
  let totalAmountOfGrazeBoxesInTheCart = 0;
  const grazeBoxBundleTwoSave10Percent = 10;

  products.forEach((e) => {
    if (e.discountedPrice) {
      subtotalAmount += e.discountedPrice * e.quantity;
      totalAmount += e.discountedPrice * e.quantity;
    } else {
      subtotalAmount += e.discountedPrice * e.quantity;
      totalAmount += e.price * e.quantity;
    }
  });

  // this is a temporary code block that should ultimately be
  // replaced with a dynamic discount system to include other types of products too
  products.forEach((e) => {
    if (e.type === "grazeBox") {
      totalNumberOfGrazeBoxesInTheCart += e.quantity;
      totalAmountOfGrazeBoxesInTheCart += e.price * e.quantity;
    }
  });

  addOns.forEach((e) => {
    subtotalAmount += e.price;
    totalAmount += e.price;
  });

  if (collection.type === "delivery") {
    totalAmount += collection.price;
  }

  if (coupon) {
    totalAmount -= discountAmount;
  }

  if (totalNumberOfGrazeBoxesInTheCart >= 2) {
    totalAmount -=
      (totalAmountOfGrazeBoxesInTheCart * grazeBoxBundleTwoSave10Percent) / 100;
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const handleButtonClick = async () => {
    if (!enablePayBtn) {
      return;
    }

    if (!collection) return alert("Please fill out the collection info");

    if (collection.type === "pickup" && !collection.location) {
      return alert("Please choose a pickup location");
    }

    if (collection.type === "delivery" && !collection.deliveryAddress) {
      return alert("Please enter a delivery address");
    }

    if (!collection.date) return alert("Please choose a collection date");

    if (!collection.timeslot)
      return alert("Please choose a time slot for the pickup date");

    if (!currentUser) {
      if (!guestCustomerInfo.firstName) {
        return alert("Please enter your first name");
      }
      if (!guestCustomerInfo.lastName) {
        return alert("Please enter your last name");
      }
      if (!collection.email) {
        return alert("Please enter an email address");
      }
    }

    if (!collection.phoneNumber || collection.phoneNumber.length < 10)
      return alert("Please enter a valid 10 digit phone number");

    //fetch the customer's name for the order
    if (currentUser) {
      const registeredUser = await axios(
        `/api/users/user?userEmail=${currentUser.email.toLowerCase()}`
      );
      registeredUserFullName =
        registeredUser.data.firstName + " " + registeredUser.data.lastName;
    }

    setSuccess(false);
    setLoading(true);

    const order = {
      products,
      addOns,
      collectionDate: new Date(collection?.date),
      collectionTime: collection?.timeslot,
      collectionType: collection.type,
      collectionLocation:
        collection.type === "pickup" ? collection?.location : "",
      coupon: coupon ? coupon : {},
      additionalNotes: additionalNotes ? additionalNotes : "n/a",
      subtotal: subtotalAmount,
      tax: taxAmount,
      total: totalAmount,
      email: currentUser
        ? currentUser.email.toLowerCase()
        : collection.email.toLowerCase(),
      customerInfo: {
        email: currentUser
          ? currentUser.email.toLowerCase()
          : collection.email.toLowerCase(),
        name: currentUser
          ? registeredUserFullName
          : guestCustomerInfo.firstName + " " + guestCustomerInfo.lastName,
        address:
          collection.type === "delivery" ? collection?.deliveryAddress : "",
        phone: collection.phoneNumber,
      },
      userRole: currentUser ? "registered" : "guest",
    };

    try {
      setEnablePayBtn(false);
      const pendingOrderResp = await axios.post("/api/orders/pending", order);
      const res = await axios.post(
        "/api/payments/create-checkout-session",
        pendingOrderResp.data
      );
      setSuccess(true);
      setLoading(false);
      localStorage.removeItem("persist:root");
      window.location.replace(res.data.url);
    } catch (error) {
      console.log(error);
      setEnablePayBtn(true);
    }
  };

  return (
    <Paper elevation={10} className={classes.wrapper} data-testid="summary">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant={"h5"} className={classes.sectionTitle}>
            Summary
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant={"subtitle1"} className={classes.sectionSubTitle}>
            Review everything carefully and proceed to the payment
          </Typography>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 250 }} aria-label="simple table">
            <TableBody>
              {products.map((product) => (
                <TableRow
                  key={product.id}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ width: "60%" }}>
                    <Typography
                      sx={{ fontWeight: "bold !important", fontSize: "15px" }}>
                      {product.name + " (x" + product.quantity + ")"}
                    </Typography>
                    {product.flavor ? (
                      <Typography
                        sx={{ fontSize: "14px", color: "#828282 !important" }}>
                        {product.category
                          ? `${
                              product.flavor
                            } ${product.category.toLowerCase()}`
                          : product.flavor}
                      </Typography>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <span>{`$`}</span>
                    <span>
                      {`${
                        product.discountedPrice
                          ? (
                              product.discountedPrice * product.quantity
                            ).toFixed(2)
                          : (product.price * product.quantity).toFixed(2)
                      } CAD`}
                    </span>
                  </TableCell>
                </TableRow>
              ))}
              {addOns.map((addOn) => (
                <TableRow
                  key={addOn.name}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}>
                  <TableCell component="th" scope="row">
                    {addOn.name}
                  </TableCell>
                  <TableCell align="right">{`$${addOn.price.toFixed(
                    2
                  )} CAD`}</TableCell>
                </TableRow>
              ))}
              {coupon && (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ color: "#fd7762" }}>
                    Discount <br /> ({props.coupon.code})
                  </TableCell>
                  <TableCell align="right" sx={{ color: "#fd7762" }}>
                    {`-$ ${props.coupon.appliedAmount} CAD`}
                  </TableCell>
                </TableRow>
              )}

              {totalNumberOfGrazeBoxesInTheCart >= 2 && (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ color: "#fd7762" }}>
                    Graze Box Bundle <br /> ({"BUY 2 OR MORE SAVE 10%"})
                  </TableCell>
                  <TableCell align="right" sx={{ color: "#fd7762" }}>
                    {`-$ ${(
                      (totalAmountOfGrazeBoxesInTheCart *
                        grazeBoxBundleTwoSave10Percent) /
                      100
                    ).toFixed(2)} CAD`}
                  </TableCell>
                </TableRow>
              )}

              {collection.type === "delivery" && (
                <TableRow
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}>
                  <TableCell component="th" scope="row">
                    Delivery
                  </TableCell>
                  <TableCell align="right">
                    {`$${props.collection.price} CAD`}
                  </TableCell>
                </TableRow>
              )}

              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}>
                  Total
                </TableCell>
                <TableCell
                  align="right"
                  sx={{ fontWeight: "bold", fontSize: "16px" }}>
                  {"$" + totalAmount.toFixed(2) + " CAD"}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs={12} textAlign="center" sx={{ mt: 5 }}>
          <Box sx={{ position: "relative" }}>
            <Button
              disabled={!enablePayBtn}
              onClick={() => handleButtonClick()}
              className={classes.proceedToPaymentBtn}
              data-testid="goto-pay-btn">
              <Typography className={classes.textStyle}>
                {!loading ? "GO TO PAY" : "Hang On..."}
              </Typography>
            </Button>

            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-10px",
                  marginLeft: "95px",
                  color: "#fff",
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

function mapStateToProps(state) {
  const {
    products,
    addOns,
    collection,
    coupon,
    additionalNotes,
    guestCustomerInfo,
  } = state.cart;
  return {
    products,
    addOns,
    collection,
    coupon,
    additionalNotes,
    guestCustomerInfo,
  };
}

export default connect(mapStateToProps)(Summary);
