/** @format */

import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      light: "#fd7762cc !important",
      main: "#fd776280 !important",
      dark: "#fd7762 !important",
      lightButton: "#FFDDD8 !important",
    },
    secondary: {
      light: "#ffffff !important",
      main: "#828282 !important",
      dark: "#464646 !important",
    },
    newSecondary: {
      light: "#ffddd8 !important",
    },
    header: {
      main: "#000000 !important",
    },
  },
  containedButtonLight: {
    backgroundColor: "#ffddd8 !important",
    color: "#fd7762 !important",
    borderRadius: "40px !important",
  },
  containedButtonDark: {
    backgroundColor: "#fd7762 !important",
    color: "#ffffff !important",
    borderRadius: "5px !important",
  },
  containedButtonWhite: {
    backgroundColor: "#ffffff !important",
    color: "#fd7762 !important",
    borderRadius: "40px !important",
  },
  filledButton: {
    color: "#fd7762 !important",
    border: "none !important", //"0px solid #fd762 !important",
    padding: "16px 35px !important",
    width: "136px !important",
    height: "47px !important",
    gap: "10px !important",
    borderRadius: "30px !important",
    fonStyle: "normal !important",
    fontWeight: "800 !important",
    fontSize: "14px !important",
    lineHeight: "14px !important",
    textTransform: "none !important",
  },

  textStyle: {
    fontFamily: "Open Sans",
  },

  mainButton: {
    padding: ".5rem 2rem",
    backgroundColor: "#fd7762",
    color: "#ffffff",
    borderRadius: "0.3rem",
    margin: "10px",
    "&:hover": {
      backgroundColor: "#fd7762",
      color: "#ffffff",
      borderColor: "#fd7762",
    },
  },
  secondaryButton: {
    padding: ".7rem",
    borderRadius: "1.4rem",
    backgroundColor: "#ffffff",
    color: "#fd7762",
    border: "2px solid #fd7762",
    margin: "10px",
    "&:hover": {
      // backgroundColor of this #fd7762 become darker
      backgroundColor: "#fd7762cc",
      border: "2px solid #fd7762cc",
      color: "#ffffff",
    },
  },
  thirdButton: {
    padding: ".7rem",
    borderRadius: "1.4rem",
    backgroundColor: "#FFDDD8",
    color: "#fd7762",
    margin: "10px 10px 10px 0",
    "&:hover": {
      backgroundColor: "#fd7762cc",
      borderColor: "#fd7762",
      color: "#ffffff",
    },
  },
  titleText: {
    fontFamily: "Playfair Display SC, serif",
    fontWeight: "600",
    lineHeight: "117.8%",
    letterSpacing: "0.09em",
    fontStyle: "normal",
    sectionText: {
      fontSize: "clamp(35px, .2rem + 10vw, 50px)",
    },
    splashText: {
      fontSize: "clamp(24px, .2rem + 10vw, 80px)",
    },
  },
  productCardText: {
    fontFamily: "Roboto",
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "34px",
    letterSpacing: "0em",
  },
});

export const userInfo = {
  margin: ".5rem 1rem",
};

export const orderDetails = {
  margin: "1rem",
};

export const modalTitle = {
  color: "#fd7762",
  fontSize: { xs: "30px", sm: "40px" },
  margin: "1rem",
};

export const tableGrid = {
  padding: "1rem",
  borderRight: "1px solid black",
  borderTop: "1px solid black",
  wordWrap: "break-word",
};

export const bottomTableGrid = {
  padding: "1rem",
};

export const footerText = {
  padding: "1rem",
  color: theme.palette.secondary.light,
  fontFamily: "Roboto",
  fontSize: "16px",
};

export const sectionLink = {
  textDecoration: "none",
  color: "inherit",
};

export const stepsOuterCircle = {
  backgroundColor: "#fff5f4",
  borderRadius: "44px",
  height: "88px",
  width: "88px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "6px",
};

export const stepsInnerCircle = {
  backgroundColor: "#ffddd8",
  borderRadius: "29px",
  height: "58px",
  width: "58px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const pinkText = {
  color: theme.palette.primary.dark,
  textAlign: "center",
};

export const companyColors = {
  margin: "4px",
  height: "26px",
  width: "26px",
  borderRadius: "25%",
};

export const inputColors = {
  "& .MuiOutlinedInput-root.Mui-focused": {
    "& > fieldset": {
      borderColor: theme.palette.primary.dark,
    },
  },
  "& label.Mui-focused": {
    color: theme.palette.primary.dark,
  },
};

export const selectColors = {
  "&.Mui-focused": {
    color: theme.palette.primary.dark,
  },
};

export const selectColorsOutline = {
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: theme.palette.primary.dark,
  },
};

export const listDesserts = {
  width: { xs: "300px", sm: "600px", md: "230px", lg: "280px" },
  height: "200px",
  overflowY: "scroll",
};
