/** @format */

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "@mui/styles";
import {
  Container,
  Toolbar,
  Typography,
  Box,
  AppBar,
  Drawer,
  MenuItem,
  Menu,
  Badge,
  Grid,
  Link,
} from "@mui/material";
import {
  productsPopulatedFromDB,
  addOnsPopulatedFromDB,
  selectProducts,
  selectProductRefs,
  updateCart,
} from "../redux/reducers/cart";
import logo from "../assets/images/logo.png";
// import CustomDrawer from "./customDrawer";
import NewCustomDrawer from "./newCustomDrawer";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import IconButton from "@mui/material/IconButton";
import { initializeApp } from "firebase/app";
import firebaseConfig from "../auth";
import { getAuth, signOut } from "firebase/auth";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material";
import { generateRandomString } from "../utils/common";
import DropDownMenuWrapper from "./dropdown/dropdownMenuWrapper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import MainActionButton from "./buttons/mainActionButton";
import SecondaryActionButton from "./buttons/secondaryActionButton";

const CORPORATE_CAKES_TYPES = [
  "branded-cake",
  "number-cake",
  "name-cake",
  "number-cupcake",
];
const CORPORATE_BOXES_TYPES = ["individual-box", "group-box"];

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "sticky !important",
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.secondary.dark,
  },
  drawer: {
    backgroundColor: theme.palette.secondary.light,
    width: "17rem !important",
  },
  toolbar: {
    // justifyContent: "space-between",
    padding: 0,
  },
  menuItems: {
    display: "none",
    ...theme.textStyle,
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginTop: "20px !important",
    },
  },
  menuItem: {
    margin: "0 15px !important",
    fontSize: "15px !important",
    fontWeight: "bold !important",
  },
  hamburgerMenuIcon: {
    display: "block",
    marginTop: "20px !important",
    paddingBottom: "20px !important",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  logo: {
    display: "none !important",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.up("md")]: {
      display: "block !important",
      marginTop: "20px !important",
    },
  },
  cartIcon: {
    fontSize: "32px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  accountIcon: {
    fontSize: "32px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  welcomeTextWrapper: {
    ...theme.containedButtonLight,
    padding: "5px 12px !important",
  },
  welcomeText: {
    fontWeight: "500 !important",
    marginLeft: "10px !important",
    marginTop: "5px !important",
    fontSize: "16px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  dropdownMenu: {
    position: "absolute",
    top: "100%", // Position below the parent container
    left: 0,
    width: "100%", // Adjust the width as needed
    zIndex: 1, // Ensure it's above other content
    opacity: 0,
    height: 0, // Start with a max height of 0
    overflow: "hidden",
    transition: "opacity 0.7s ease-in-out, height 0.4s ease-in-out",
    display: "flex",
    justifyContent: "space-between",
    padding: "60px 0 60px 0",
    backgroundColor: "#ffffff !important",
  },
  dropdownMenuOpen: {
    height: "300px !important",
    opacity: 1,
    border: "1px solid #000",
  },
  navbarItemWrapper: {
    display: "flex",
    marginRight: "30px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  navbarItem: {
    fontFamily: "Inter",
    marginRight: "10px !important",
    "&:hover": {
      cursor: "pointer",
    },
  },
  textStyle: { ...theme.textStyle },
  secondaryBtn: {
    ...theme.containedButtonLight,
    padding: "10px 20px",
    marginRight: "20px !important",
    "&.MuiButtonBase-root .MuiButton-root": {
      lineHeight: "0 !important",
    },
    [theme.breakpoints.up("sm")]: {
      padding: "15px 35px",
      width: "100%",
    },
  },
  mainBtn: {
    ...theme.containedButtonDark,
    padding: "10px 20px",
    marginTop: "5px",
    textTransform: "none",
    fontFamily: "Inter",
    [theme.breakpoints.up("sm")]: {},
  },
}));

export default function NewTopAppBar() {
  initializeApp(firebaseConfig());
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const productsInCart = useSelector(selectProductRefs);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [shopDropdownIsOpen, setShopDropdownIsOpen] = useState(false);
  const [servicesDropdownMenuIsOpen, setServicesDropdownIsOpen] =
    useState(false);
  const [dropdownMenuType, setDropdownMenuType] = useState(null);

  let userEmail = null;
  let products = [];

  // calculates the total number of products in the cart upon initial loading
  // to show in the shopping bag badge
  let totalNumberOfProducts = 0;
  for (let i = 0; i < productsInCart.length; i++) {
    totalNumberOfProducts += productsInCart[i].quantity;
  }

  useEffect(() => {
    let storedTempEmail = localStorage.getItem("tempEmail");
    let randomString = null;
    let tempEmail = null;

    if (!storedTempEmail || storedTempEmail == "") {
      randomString = generateRandomString();
      tempEmail = randomString + "@outlook.com";
      localStorage.setItem("tempEmail", tempEmail);
    }

    userEmail = currentUser
      ? currentUser.email
      : storedTempEmail
      ? storedTempEmail
      : tempEmail;
  }, []);

  useEffect(() => {
    async function fetchCartProducts() {
      const res = await axios.get("/api/cart?email=" + userEmail);
      if (res.status === 200) {
        const userCart = res.data;

        if (userCart && userCart.products) {
          dispatch(updateCart(userCart.products));
        }

        if (userCart) {
          for (let i = 0; i < userCart?.products.length; i++) {
            let product = null;
            let productType = userCart.products[i].type;

            // making sure the product in the user's cart does exist in standard cakes collection
            if (productType === "standardCake") {
              let res = await axios.get(
                "/api/app/data/standardCakes?productId=" +
                  userCart.products[i].productId
              );

              product = res.data;
            }

            // making sure the product in the user's cart does exist in standard cakes collection
            if (productType === "cheesecake") {
              let res = await axios.get(
                "/api/app/data/cheesecakes?cheesecakeId=" +
                  userCart.products[i].productId
              );

              product = res.data;
            }

            // making sure the product in the user's cart does exist in standard cakes collection
            if (productType === "grazeBox") {
              let res = await axios.get(
                "/api/app/data/grazeBoxes/" + userCart.products[i].productId
              );

              product = res.data;
            }

            // making sure the product in the user's cart does exist in patisserie collection
            if (productType === "regular-order-dessert") {
              let res = await axios.get(
                "/api/app/data/patisseries/pid?productId=" +
                  userCart.products[i].dessertId
              );

              product = res.data;
            }

            // making sure the product in the user's cart does exist in custom cakes collection
            if (productType === "custom-cake") {
              let res = await axios.get(
                "/api/app/data/customCake?customCakeId=" +
                  userCart.products[i].cakeId
              );

              product = res.data;
            }

            // making sure the product in the user's cart does exist in custom cakes collection
            if (productType === "holidayCake") {
              let res = await axios.get(
                "/api/app/data/holidayProducts/holidayCake?customCakeId=" +
                  userCart.products[i].cakeId
              );

              product = res.data;
            }

            if (productType === "christmas") {
              let res = await axios.get(
                "/api/app/data/holidayProducts/holidayCake?customCakeId=" +
                  userCart.products[i].productId
              );

              product = res.data;
            }

            if (CORPORATE_CAKES_TYPES.indexOf(productType) !== -1) {
              let res = await axios.get(
                "/api/app/data/corporate-cake?cakeId=" +
                  userCart.products[i].productId
              );

              product = res.data;
            }

            if (CORPORATE_BOXES_TYPES.indexOf(productType) !== -1) {
              let res = await axios.get(
                "/api/app/data/corporate-gift-box?boxId=" +
                  userCart.products[i].productId
              );

              product = res.data;
            }

            // if the product in the cart exists, then continue with the logic,
            // otherwise it's no longer available and needs to be ignored
            if (product) {
              products.push(userCart.products[i]);
            }
          }

          dispatch(productsPopulatedFromDB(products));

          if (userCart.addOns) {
            dispatch(addOnsPopulatedFromDB(userCart.addOns));
          } else {
            dispatch(addOnsPopulatedFromDB([]));
          }
        }
      }
    }

    fetchCartProducts();
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    navigate("/my-orders");
  };

  const logOut = () => {
    signOut(auth)
      .then(() => {
        localStorage.removeItem("user");
        navigate("/");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const toggleDropdown = (menuType) => {
    setDropdownMenuType(menuType);
    if (menuType === "shop") {
      setShopDropdownIsOpen(!shopDropdownIsOpen);
      setServicesDropdownIsOpen(false);
    }
    if (menuType === "services") {
      setServicesDropdownIsOpen(!servicesDropdownMenuIsOpen);
      setShopDropdownIsOpen(false);
    }
  };

  return (
    <AppBar className={classes.appBar} elevation={2} data-testid="appbar">
      <Drawer
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          "& .MuiPaper-root": {
            width: "80%",
          },
        }}>
        <NewCustomDrawer setIsDrawerOpen={setIsDrawerOpen} />
      </Drawer>
      <Container fixed maxWidth="xl">
        <Toolbar className={classes.toolbar}>
          <Grid
            container
            sx={{
              justifyContent: "space-between",
              pb: { md: "20px !important" },
            }}>
            <Grid item className={classes.hamburgerMenuIcon}>
              <IconButton
                size="large"
                edge="start"
                aria-label="menu"
                sx={{ mr: 2, color: "#fd7762" }}
                onClick={() => setIsDrawerOpen(!isDrawerOpen)}>
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item className={classes.logo}>
              <Box component="div" onClick={() => navigate("/")}>
                <img
                  src={logo}
                  width={"150px"}
                  height={"100%"}
                  alt="cakeyhand logo"
                />
              </Box>
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item className={classes.menuItems}>
                  <Box
                    className={classes.navbarItemWrapper}
                    onClick={() => navigate("/")}>
                    <Typography className={classes.navbarItem}>Home</Typography>
                  </Box>
                  <Box
                    className={classes.navbarItemWrapper}
                    onClick={() => toggleDropdown("shop")}>
                    <Typography className={classes.navbarItem}>Shop</Typography>
                    {shopDropdownIsOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Box>
                  <Box
                    className={classes.navbarItemWrapper}
                    onClick={() => toggleDropdown("services")}>
                    <Typography className={classes.navbarItem}>
                      Services
                    </Typography>
                    {servicesDropdownMenuIsOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </Box>
                  <Box
                    className={classes.navbarItemWrapper}
                    onClick={() => navigate("/holiday/christmas")}>
                    <Typography
                      className={classes.navbarItem}
                      sx={{ color: "#fd7762", fontWeight: "bold" }}>
                      Christmas
                    </Typography>
                  </Box>
                  <Box
                    className={classes.navbarItemWrapper}
                    onClick={() => navigate("/about-us")}>
                    <Typography className={classes.navbarItem}>
                      About
                    </Typography>
                  </Box>
                </Grid>
                {currentUser && (
                  <Grid item sx={{ mt: "20px !important" }}>
                    <Box sx={{ display: "flex" }}>
                      <Badge
                        badgeContent={totalNumberOfProducts}
                        sx={{
                          mr: 3,
                          alignItems: "center",
                          "& .MuiBadge-badge": {
                            color: "#fff",
                            backgroundColor: "#fd7762",
                            marginTop: "5px",
                          },
                        }}
                        onClick={() => navigate("/cart")}>
                        <ShoppingBagOutlinedIcon className={classes.cartIcon} />
                      </Badge>
                      <Box
                        className={classes.welcomeTextWrapper}
                        onClick={handleMenu}>
                        <Badge>
                          <AccountCircleOutlinedIcon
                            className={classes.accountIcon}
                          />
                        </Badge>
                        <span className={classes.welcomeText}>
                          Hi {currentUser.firstName}
                        </span>
                      </Box>

                      <Menu
                        fontFamily="Inter"
                        id="menu-appbar"
                        data-testid="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}>
                        <MenuItem onClick={() => handleClose()}>
                          My Orders
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleClose();
                            logOut();
                          }}>
                          Log Out
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Grid>
                )}

                {!currentUser && (
                  <Grid item sx={{ mt: "20px !important" }}>
                    <Box sx={{ display: "flex" }}>
                      <Badge
                        badgeContent={totalNumberOfProducts}
                        sx={{
                          mr: 3,
                          "& .MuiBadge-badge": {
                            color: "#fff",
                            backgroundColor: "#fd7762",
                          },
                        }}
                        onClick={() => navigate("/cart")}>
                        <ShoppingBagOutlinedIcon className={classes.cartIcon} />
                      </Badge>
                      <Typography
                        sx={{
                          pt: "7px !important",
                          paddingRight: "20px",
                          color: "#fd7762",
                          fontFamily: "Inter",
                        }}>
                        Sign in
                      </Typography>
                      <MainActionButton
                        clickEvent={() => navigate("/signup")}
                        testId={"signup-btn"}
                        styleObj={classes.mainBtn}>
                        Sign up
                      </MainActionButton>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
        {(shopDropdownIsOpen || servicesDropdownMenuIsOpen) && (
          <DropDownMenuWrapper
            menuType={dropdownMenuType}
            setShopDropdownIsOpen={setShopDropdownIsOpen}
            setServicesDropdownIsOpen={
              setServicesDropdownIsOpen
            }></DropDownMenuWrapper>
        )}
      </Container>
    </AppBar>
  );
}
