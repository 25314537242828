import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectChosenGrazeBox } from "../../redux/reducers/grazeBox";
import { useMediaQuery, useTheme } from "@mui/material";
import axios from "axios";
import {
  Box,
  Typography,
  Container,
  ToggleButtonGroup,
  ToggleButton,
  Divider,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import MainActionButton from "../../components/buttons/mainActionButton";
import MultiImageShow from "../../components/multiImageShow";
import { updateCart } from "../../redux/reducers/cart";
import Header from "../../components/header";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";
import StarIcon from "@mui/icons-material/Star";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import VerifiedIcon from "@mui/icons-material/Verified";
import ReportIcon from "@mui/icons-material/Report";

const useStyles = makeStyles((theme) => ({
  orderNow: {
    ...theme.containedButtonDark,
    padding: "10px 20px",
    marginTop: "5px",
    textTransform: "none",
    fontFamily: "Inter",
    width: "100%",
    fontSize: "18px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
  },
}));

const GrazeBoxDetail = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  console.log(isMobileScreen);
  const chosenGrazeBox = useSelector(selectChosenGrazeBox);

  const [size, setSize] = useState(chosenGrazeBox.sizes[0]);
  const [diet, setDiet] = useState(chosenGrazeBox.dietaryOptions[0]);
  const [giftPackaging, setGiftPackaging] = useState(false);
  const [sizePrice, setSizePrice] = useState(chosenGrazeBox.sizes[0].price);
  const [dietPrice, setDietPrice] = useState(
    chosenGrazeBox.dietaryOptions[0].priceAdjustments[0].amount
  );
  const [giftPackagingPrice, setGiftPackagingPrice] = useState(0);
  const [addon, setAddon] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const timer = useRef();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  let tempEmail = localStorage.getItem("tempEmail");

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  useEffect(() => {
    setTimeout(function () {
      setSuccess(false);
    }, 3000);
  }, [success]);

  const handleSizeChange = (event, newSize) => {
    if (newSize) {
      setSize(newSize);
      setSizePrice(newSize.price);
    }
  };

  const handleDietChange = (event, newDiet) => {
    if (newDiet) {
      setDiet(newDiet);
      setDietPrice(
        newDiet.priceAdjustments.find((p) => p.sizeId === size.sizeId).amount
      );
    }
  };

  const handleGiftPackagingChange = () => {
    setGiftPackaging((prev) => {
      const newGiftPackaging = !prev; // Toggle the gift packaging
      setGiftPackagingPrice(newGiftPackaging ? 3 : 0); // Use the updated value to set the price
      return newGiftPackaging;
    });
  };

  const handleAddToCart = async () => {
    if (!size) return alert("Please select a size");
    if (!diet) return alert("Please select a dietary restriction");

    setSuccess(false);
    setLoading(true);

    const product = {
      id: Date.now(),
      productId: chosenGrazeBox._id,
      name: "Graze Box",
      style: chosenGrazeBox.style,
      diet: diet.name,
      imgPath: chosenGrazeBox.images.primary,
      size: size.name,
      quantity: 1,
      type: "grazeBox",
      price: sizePrice + dietPrice + giftPackagingPrice,
      packagingType: giftPackaging ? "gift" : "standard",
    };

    const res = await axios.post("/api/cart/addProduct/", {
      userEmail: currentUser ? currentUser.email : tempEmail,
      product,
    });

    timer.current = window.setTimeout(() => {
      if (res.status === 200) {
        dispatch(updateCart(res.data));
        // dispatch(resetToInitial());
        setSuccess(true);
        setLoading(false);
      }
    }, 1000);
  };

  return (
    <>
      <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: 4, sm: 12 },
            paddingTop: 8,
            paddingBottom: 8,
            marginBottom: 8,
          }}>
          {/* Image Gallery */}
          <MultiImageShow images={chosenGrazeBox.images}></MultiImageShow>

          {/* Product Details */}
          <Box sx={{ flex: 2 }}>
            <Typography variant="h4" fontWeight="bold">
              {chosenGrazeBox.name}
            </Typography>
            <Typography sx={{ color: "gray", marginBottom: 2 }}>
              ⭐ 5.0 ({chosenGrazeBox.reviewCount} reviews)
            </Typography>
            <Typography sx={{ color: "gray", marginBottom: 2 }}>
              {chosenGrazeBox.shortDescription}
            </Typography>
            <Typography variant="h5" sx={{ marginBottom: 2 }}>
              {`$ ${(sizePrice + dietPrice + giftPackagingPrice).toFixed(
                2
              )} CAD`}
            </Typography>
            <Typography sx={{ color: "green", marginBottom: 2 }}>
              Local Delivery Available
            </Typography>

            <Divider />

            {/* Features */}
            <Box sx={{ marginBottom: 3, marginTop: 3, display: "flex" }}>
              <Box sx={{ mr: 8 }}>
                <Box sx={{ display: "flex" }}>
                  <StarIcon sx={{ mr: 1 }} />
                  <Typography sx={{ mb: 3 }}>Prepared by Experts</Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <LocalShippingIcon sx={{ mr: 1 }} />
                  <Typography>On-time delivery</Typography>
                </Box>
              </Box>
              <Box>
                <Box sx={{ display: "flex" }}>
                  <CardGiftcardIcon sx={{ mr: 1 }} />
                  <Typography sx={{ mb: 3 }}>
                    Gift Packaging Available
                  </Typography>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <VerifiedIcon sx={{ mr: 1 }} />
                  <Typography>Satisfaction Guaranteed</Typography>
                </Box>
              </Box>
            </Box>

            <Divider />

            {/* Size Selection */}
            <Box sx={{ marginBottom: 3, marginTop: 3 }}>
              <Typography fontWeight="bold" sx={{ marginBottom: 1 }}>
                Size: {size.name}
              </Typography>
              <ToggleButtonGroup
                value={size}
                exclusive
                onChange={handleSizeChange}>
                {chosenGrazeBox.sizes.map((size) =>
                  isMobileScreen ? (
                    <ToggleButton value={size}>
                      <div style={{ textAlign: "center" }}>
                        <Typography
                          sx={{
                            textTransform: "capitalize",
                          }}>{`${size.name} `}</Typography>
                        <Typography
                          sx={{ textTransform: "lowercase", fontSize: "12px" }}>
                          {` (serves up to ${size.servesUpTo})`}
                        </Typography>
                      </div>
                    </ToggleButton>
                  ) : (
                    <ToggleButton value={size}>
                      <span
                        style={{
                          marginRight: "5px",
                          textTransform: "capitalize",
                        }}>{`${size.name} `}</span>
                      <span
                        style={{
                          textTransform: "lowercase",
                        }}>
                        {` (serves up to ${size.servesUpTo})`}
                      </span>
                    </ToggleButton>
                  )
                )}
              </ToggleButtonGroup>
            </Box>

            <Divider />

            {/* Dietary Restrictions */}
            <Box sx={{ marginBottom: 3, marginTop: 3 }}>
              <Typography fontWeight="bold" sx={{ marginBottom: 1 }}>
                Dietary Restriction: {diet.name}
              </Typography>
              <ToggleButtonGroup
                value={diet}
                exclusive
                onChange={handleDietChange}>
                {chosenGrazeBox.dietaryOptions.map((diet) => (
                  <ToggleButton value={diet}>
                    <span style={{ textTransform: "capitalize" }}>
                      {diet.name}
                    </span>
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
            </Box>

            <Divider />

            {/* Size Selection */}
            <Box sx={{ marginBottom: 3, marginTop: 3 }}>
              <Typography fontWeight="bold" sx={{ marginBottom: 1 }}>
                Gift Packaging: {giftPackaging ? "Yes" : "No"}
              </Typography>
              <ToggleButtonGroup
                value={giftPackaging ? "Yes" : "No"}
                exclusive
                onChange={handleGiftPackagingChange}>
                <ToggleButton value={"No"}>
                  {" "}
                  <span style={{ textTransform: "capitalize" }}>{`No`}</span>
                </ToggleButton>
                <ToggleButton value={"Yes"}>
                  {" "}
                  <span style={{ textTransform: "capitalize" }}>
                    {`Yes ($3.00)`}
                  </span>
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>

            <Divider />

            <Box
              sx={{
                display: "flex",
                mt: 4,
                mb: 4,
              }}>
              <ReportIcon sx={{ mr: 1, color: "orange" }} />
              <Typography sx={{ color: "orange", fontWeight: "bold" }}>
                {chosenGrazeBox.note}
              </Typography>
            </Box>

            {/* Add to Cart */}
            <MainActionButton
              clickEvent={handleAddToCart}
              testId={""}
              styleObj={classes.orderNow}>
              {" "}
              Add to cart
            </MainActionButton>
          </Box>
        </Box>
      </Container>
      <Footer></Footer>
    </>
  );
};

export default GrazeBoxDetail;
