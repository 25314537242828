import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  primaryImgStyle: {
    width: "100% !important",
    height: "400px !important",
    [theme.breakpoints.up("sm")]: {
      width: "600px !important",
      height: "600px !important",
    },
  },
  secondaryCakeImg: {
    width: "100% !important",
    height: "90px !important",
  },
  gridContainer: {
    // height: "310px",
    // width: "100% !important",
    // display: "grid !important",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "repeat(3, 1fr)",
    gap: "10px",
  },
  gridColumn: {
    gridColumn: "1 / 4",
    gridRow: "1 / 4",
  },
  scrollContainer: {
    height: "310px !important",
    overflowY: "scroll",
  },
}));

export default function MultiImageShow({ images }) {
  const classes = useStyles();
  const [primaryImg, setPrimaryImg] = useState(images.primary);
  const [productImages, setProductImages] = useState(images);

  const handleImageClick = (img, i) => {
    // Swap primary image with the clicked secondary image
    const updatedSecondaryImages = [...productImages.secondary];
    updatedSecondaryImages[i] = primaryImg; // Replace clicked secondary image with the current primary image
    setPrimaryImg(img); // Set the clicked secondary image as the new primary image

    // Update the secondary images state
    setProductImages((prevState) => ({
      ...prevState,
      secondary: updatedSecondaryImages,
    }));
  };

  return (
    <Box data-testid="multi-image-show">
      <Grid container className={classes.gridContainer}>
        <Grid item className={classes.gridColumn}>
          <img
            className={classes.primaryImgStyle}
            src={primaryImg}
            alt="image"
            data-testid="primary-image"
          />
        </Grid>

        {/* {productImages.secondary.length > 0 && (
          <Box
            className={
              productImages.secondary.length > 3 && classes.scrollContainer
            }>
            {productImages.secondary.map((image, index) => (
              <Grid item xs={2} sx={{ mr: 3 }}>
                <img
                  className={classes.secondaryCakeImg}
                  src={image}
                  alt="image"
                  onClick={() => handleImageClick(image, index)}
                  data-testid="secondary-image"
                />
              </Grid>
            ))}
          </Box>
        )} */}
      </Grid>
    </Box>
  );
}
