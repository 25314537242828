/** @format */

import { React, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import {
  collectionDeliveryAddressChosen,
  selectCollection,
} from "../../../redux/reducers/cart";

export default function Delivery({ placeholder, icon }) {
  const dispatch = useDispatch();
  const collection = useSelector(selectCollection);
  const [delivery, setDelivery] = useState(collection.deliveryAddress);
  const [deliveryError, setDeliveryError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    const input = event.target.value;
    if (!input) {
      setDeliveryError(true);
    } else {
      setDeliveryError(false);
    }
    dispatch(collectionDeliveryAddressChosen(input));
    setDelivery(input);
  };

  const getLabelStyles = () => {
    if (isFocused) {
      return {
        paddingLeft: "0",
        transform: "translate(15px, -20px) scale(0.75)",
        marginTop: "10px",
      };
    } else {
      if (delivery) {
        return { paddingLeft: "0" };
      } else {
        return { paddingLeft: "35px" };
      }
    }
  };

  return (
    <FormControl>
      <Typography sx={{ mb: 2 }}>
        Delivery is limited to St. John's and nearby cities (Mt. Pearl,
        Paradise, CBS, Torbay, Goulds, etc.)
      </Typography>
      <TextField
        sx={{ maxWidth: "230px", mb: 2 }}
        error={deliveryError}
        helperText={deliveryError ? "invalid address" : ""}
        variant="outlined"
        value={delivery}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        InputProps={{
          sx: { borderRadius: "20px" },
          startAdornment: (
            <InputAdornment position="start">
              <IconButton edge="start" onClick={() => {}}>
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: isFocused || delivery.length > 0,
          style: getLabelStyles(),
        }}
        label={placeholder}
        data-testid="delivery"
      />
    </FormControl>
  );
}
