/** @format */

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  grazebox: {
    name: null,
    imgPath: null,
    size: null,
    diet: null,
    price: null,
  },
  chosenGrazeBox: null,
};

export const grazeBoxSlice = createSlice({
  name: "grazeBox",
  initialState,
  reducers: {
    chosenGrazeBoxSelected: (state, action) => {
      state.chosenGrazeBox = action.payload;
    },
    resetToInitial: () => initialState,
  },
});

export const selectChosenGrazeBox = (state) => state.grazeBox.chosenGrazeBox;
export const selectGrazeBoxInfo = (state) => state.grazeBox.grazebox;

export const { chosenGrazeBoxSelected } = grazeBoxSlice.actions;

export default grazeBoxSlice.reducer;
