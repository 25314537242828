/** @format */

import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import ComingSoon from "../comingSoon";

const useStyles = makeStyles(() => ({
  menuItem: {
    "&:hover": {
      cursor: "pointer !important",
    },
  },
  dropdownSubMenu: {
    padding: "0 15px 0 15px !important",
  },
  dropdownSubMenuTitle: {
    fontWeight: "bold !important",
    textTransform: "uppercase !important",
    marginRight: "20px !important",
    color: "#000000 !important",
  },
  dropdownSubMenuItem: {
    color: "#808080 !important",
    marginBottom: "35px !important",
    "&:hover": {
      cursor: "pointer !important",
      color: "#fd7762 !important",
    },
  },
  serviceMenuItem: {
    marginRight: "80px !important",
  },
}));

export default function DropDownMenuItem({
  title,
  values,
  menuType,
  setShopDropdownIsOpen,
  setServicesDropdownIsOpen,
}) {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleClick = (href, title) => {
    if (
      title === "accessories" ||
      title === "weddings" ||
      title === "corporate events"
    ) {
      alert(
        "sorry, we are still working on these features. Please check back later"
      );
      setShopDropdownIsOpen(false);
      setServicesDropdownIsOpen(false);
    } else {
      setShopDropdownIsOpen(false);
      setServicesDropdownIsOpen(false);
      navigate(href);
    }
  };

  return (
    <Box
      className={`${classes.menuItem} ${
        menuType === "services" ? classes.serviceMenuItem : ""
      }`}>
      <Box sx={{ display: "flex", mb: "35px" }}>
        <Typography className={classes.dropdownSubMenuTitle}>
          {title}
        </Typography>
        {(title === "accessories" ||
          title === "weddings" ||
          title === "corporate events") && <ComingSoon></ComingSoon>}
      </Box>

      {values.map((menuItem) => {
        return (
          <Box>
            {menuItem.type === "img" ? (
              <img
                src={menuItem.value}
                onClick={() => handleClick(menuItem.href, title)}></img>
            ) : (
              <Typography
                className={classes.dropdownSubMenuItem}
                key={menuItem.value}
                onClick={() => handleClick(menuItem.href, title)}>
                {menuItem.value}
              </Typography>
            )}
          </Box>
        );
      })}
    </Box>
  );
}
