import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, Container } from "@mui/material";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Main from "./main";
import Product from "./products";
import AddOns from "./addOns/addOns";
import Collection from "../ShoppingCart/collection/collection";
import Coupon from "./coupon";
import Summary from "./summary";
import EmptyCart from "./emptyCart";
import AdditionalNotes from "./additionalNotes";
import {
  selectAddOns,
  selectProductRefs,
  selectProducts,
  addedFinalProductToTheCart,
} from "../../redux/reducers/cart";
import axios from "axios";

const CORPORATE_PRODUCT_TYPES = [
  "branded-cake",
  "number-cake",
  "name-cake",
  "number-cupcake",
  "individual-box",
  "group-box",
];

export default function Cart() {
  const dispatch = useDispatch();
  const productRefs = useSelector(selectProductRefs);
  // console.log(productRefs);
  const products = useSelector(selectProducts);
  const addOns = useSelector(selectAddOns);
  const [dataIsReady, setDataIsReady] = useState(false);

  // FIXME --- this does not reload when quantity is changed
  useEffect(async () => {
    productRefs.map(async (product) => {
      // console.log(product);
      let singleProduct = {};
      let customizationOptionsList = [];
      if (product.type === "custom-cake" && product.cakeId) {
        const existingProduct = products.find((p) => p.id === product.id);

        const productDesign = await axios.get(
          "/api/app/data/customCake?customCakeId=" + product.cakeId
        );

        let productFlavorPrice = 0;
        product.flavorIds.forEach(async (flavorId) => {
          const productFlavor = await axios.get(
            "/api/app/data/flavor?flavorId=" + flavorId
          );
          productFlavorPrice += productFlavor.data.price;
        });

        const productSize = await axios.get(
          "/api/app/data/size?sizeId=" + product.sizeId
        );

        for (const property in product.customizationOptions) {
          let option = product.customizationOptions[property];
          let res = await axios.get(
            "/api/app/data/customizationOption?optionId=" + option.parentId
          );

          if (res.status != 200) {
            throw new Error(`HTTP error: ${res.status}`);
          }

          let productCustomizationOptions = res.data;
          let customizationSelectedByUser = null;

          if (productCustomizationOptions.name === "flower") {
            customizationSelectedByUser =
              productCustomizationOptions.values.find(
                (option) => option.id === product.customizationOptions.flower.id
              );
            customizationSelectedByUser["selection"] =
              product.customizationOptions.flower.selection;
          }

          if (productCustomizationOptions.name === "numberCake") {
            customizationSelectedByUser =
              product.customizationOptions.numberCakeNumber;
          }

          if (productCustomizationOptions.name === "colorTheme") {
            customizationSelectedByUser =
              productCustomizationOptions.values.find(
                (option) =>
                  option.id === product.customizationOptions.colorTheme.id
              );
            customizationSelectedByUser["selection"] =
              product.customizationOptions.colorTheme.selection;
          }

          if (productCustomizationOptions.name === "flakes") {
            customizationSelectedByUser =
              productCustomizationOptions.values.find(
                (option) =>
                  option.id === product.customizationOptions.flakeColor.id
              );
            customizationSelectedByUser["selection"] =
              product.customizationOptions.flakeColor.selection;
          }

          if (customizationSelectedByUser != null) {
            customizationOptionsList.push(customizationSelectedByUser);
          }
        }

        // even if the customization is free, it MUST have a property "price" set to default value of 0 // adding this comment to test a deployment - DELETE AFTER
        let customizationCost = 0;
        for (let i = 0; i < customizationOptionsList.length; i++) {
          customizationCost += customizationOptionsList[i].price;
        }

        singleProduct = {
          id: product.id,
          name: "Specialty Cake",
          imgPath: productDesign.data.images.primary,
          price:
            productDesign.data.startingPrice +
            productSize.data.price +
            productFlavorPrice +
            customizationCost,
          size: productSize.data.label,
          flavor: product.flavor,
          customizations: customizationOptionsList,
          type: product.type,
          quantity: existingProduct
            ? existingProduct.quantity
            : product.quantity,
          minQTY: 1,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (product.type === "holidayCake" && product.cakeId) {
        const existingProduct = products.find((p) => p.id === product.id);

        const productDesign = await axios.get(
          "/api/app/data/holidayProducts/holidayCake?customCakeId=" +
            product.cakeId
        );

        let productFlavorPrice = 0;
        product.flavorIds.forEach(async (flavorId) => {
          const productFlavor = await axios.get(
            "/api/app/data/flavor?flavorId=" + flavorId
          );
          productFlavorPrice += productFlavor.data.price;
        });

        const productSize = await axios.get(
          "/api/app/data/size?sizeId=" + product.sizeId
        );

        singleProduct = {
          id: product.id,
          name: productDesign.data.displayName,
          imgPath: productDesign.data.images.primary,
          price:
            productDesign.data.startingPrice +
            productSize.data.price +
            productFlavorPrice,
          size: productSize.data.label,
          flavor: product.flavor,
          customizations: [],
          type: product.type,
          quantity: existingProduct
            ? existingProduct.quantity
            : product.quantity,
          minQTY: 1,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (product.type === "christmas" && product.productId) {
        const existingProduct = products.find((p) => p.id === product.id);

        const productDesign = await axios.get(
          "/api/app/data/holidayProducts/holidayCake?customCakeId=" +
            product.productId
        );

        singleProduct = {
          id: product.id,
          name: productDesign.data.displayName,
          imgPath: productDesign.data.images.primary,
          price: productDesign.data.basePrices[0].price,
          flavor: product.flavor,
          customizations: [],
          type: product.type,
          quantity: existingProduct
            ? existingProduct.quantity
            : product.quantity,
          minQTY: 1,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (product.type === "wedding-cake") {
        singleProduct = {
          id: product.id,
          name: product.name,
          imgPath: product.imgPath,
          size: product.size,
          flavor: product.flavor,
          price: product.price,
          type: product.type,
          quantity: product.quantity,
          customizations: product.customizations,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (product.type === "wedding-dessert") {
        singleProduct = {
          id: product.id,
          name: product.name,
          imgPath: product.imgPath,
          price: product.price,
          type: product.type,
          quantity: product.quantity,
          itemsPerSet: product.itemsPerSet,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (product.type === "wedding-setup-service") {
        singleProduct = {
          id: product.id,
          name: product.name,
          type: product.type,
          quantity: product.quantity,
          price: product.price,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (product.type === "standardCake") {
        const existingProduct = products.find((p) => p.id === product.id);
        let price = 0;

        const res = await axios.get(
          "/api/app/data/standardCakes?productId=" + product.productId
        );

        const standardCake = res.data;

        if (product.size === "7 inch") {
          price = standardCake.prices[0];
        }

        if (product.size === "8 inch") {
          price = standardCake.prices[1];
        }

        if (product.size === "9 inch") {
          price = standardCake.prices[2];
        }

        if (product.size === "half sheet") {
          price = standardCake.prices[3];
        }

        if (product.size === "full sheet") {
          price = standardCake.prices[4];
        }

        singleProduct = {
          id: product.id,
          name: product.name,
          category: product.category,
          imgPath: standardCake.images.primary,
          price,
          size: product.size,
          flavor: product.flavor,
          color: product.color,
          type: product.type,
          quantity: existingProduct
            ? existingProduct.quantity
            : product.quantity,
          minQTY: 1,
        };

        // console.log(singleProduct);

        dispatch(addedFinalProductToTheCart(singleProduct));
      }
      if (product.type === "cheesecake") {
        const existingProduct = products.find((p) => p.id === product.id);

        singleProduct = {
          id: product.id,
          name: product.name,
          imgPath: product.imgPath,
          price: product.price,
          size: product.size,
          flavor: product.flavor,
          type: product.type,
          quantity: existingProduct
            ? existingProduct.quantity
            : product.quantity,
          minQTY: 1,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (product.type === "grazeBox") {
        const existingProduct = products.find((p) => p.id === product.id);

        singleProduct = {
          id: product.id,
          name: product.name,
          imgPath: product.imgPath,
          price: product.price,
          size: product.size,
          style: product.style,
          diet: product.diet,
          type: product.type,
          packaging: product.packagingType,
          quantity: existingProduct
            ? existingProduct.quantity
            : product.quantity,
          minQTY: 1,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (product.type === "regular-order-dessert") {
        let dessert = null;
        let featuredProduct = null;
        const existingProduct = products.find((p) => p.id === product.id);

        let res = await axios.get(
          "/api/app/data/patisseries/pid?productId=" + product.dessertId
        );

        dessert = res.data;

        if (product.featuredProductId) {
          res = await axios.get(
            `/api/app/data/featuredProduct/getFeaturedProductById?productId=${product.featuredProductId}`
          );

          if (res.status === 200) {
            featuredProduct = res.data;
          }
        }

        let discountedPrice = 0;
        if (featuredProduct) {
          if (featuredProduct.discount.type === "percentage") {
            const discountAmount =
              (dessert.basePrices[0].price * featuredProduct.discount.amount) /
              100;
            discountedPrice = dessert.basePrices[0].price - discountAmount;
          }
        }

        singleProduct = {
          id: product.id,
          featuredProduct,
          quantity: existingProduct
            ? existingProduct.quantity
            : product.quantity,
          minQTY: dessert.productConfig[1].value,
          name: dessert.name,
          flavor: dessert.flavor ? dessert.flavor : "",
          imgPath: dessert.images[0].value,
          price: dessert.basePrices[0].price,
          discountedPrice,
          itemsPerSet: dessert.productConfig[1].value,
          type: product.type,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (product.type === "macaron") {
        const existingProduct = products.find((p) => p.id === product.id);
        const res = await axios.get("/api/app/data/macarons");
        const macaronInfo = res.data;
        const selectedPackaging = macaronInfo.packages.find(
          (p) => p.name === product.packaging
        );

        singleProduct = {
          id: product.id,
          name: product.name,
          imgPath: product.imgPath,
          size: product.size,
          flavors: product.flavors,
          price: macaronInfo.unitPrice * product.size + selectedPackaging.price,
          type: product.type,
          quantity: existingProduct
            ? existingProduct.quantity
            : product.quantity,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }
      if (product.type === "assortment") {
        const existingProduct = products.find((p) => p.id === product.id);
        const res = await axios.get(
          "/api/app/data/assortment?assortmentId=" + product.productId
        );
        const assortmentInfo = res.data;

        singleProduct = {
          id: product.id,
          name: assortmentInfo.name,
          imgPath: assortmentInfo.imgPath,
          price: assortmentInfo.price,
          type: product.type,
          quantity: existingProduct
            ? existingProduct.quantity
            : product.quantity,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }

      if (CORPORATE_PRODUCT_TYPES.indexOf(product.type) !== -1) {
        singleProduct = {
          id: product.id,
          name: product.name,
          imgPath: product.imgPath,
          size: product.size,
          flavor: product.flavor,
          price: product.price,
          type: product.type,
          quantity: product.quantity,
          customizations: product.customizations,
        };

        dispatch(addedFinalProductToTheCart(singleProduct));
      }
    });

    setDataIsReady(true);
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        margin: 0,
        display: "flex",
        flexDirection: "column",
      }}>
      <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      <span>
        {products.length > 0 && dataIsReady ? (
          <Box>
            <Main></Main>
            <Box sx={{ backgroundColor: "#fd776280", pt: 2, pb: 2 }}>
              <Container>
                <Grid container>
                  <Grid item xs={12} sm={7.7}>
                    <Product products={products}></Product>
                    <Collection></Collection>
                    <AddOns addOns={addOns}></AddOns>
                    {/* <AdditionalNotes></AdditionalNotes> */}
                  </Grid>
                  <Grid item xs={0.3}></Grid>
                  <Grid item xs={12} sm={4}>
                    <Coupon></Coupon>
                    <Summary></Summary>
                  </Grid>
                </Grid>
              </Container>
            </Box>
          </Box>
        ) : (
          <EmptyCart></EmptyCart>
        )}
      </span>

      <Box
        sx={{
          backgroundColor: productRefs.length > 0 ? "#fff" : "#fd776280",
          marginTop: "auto",
        }}>
        <Footer></Footer>
      </Box>
    </Box>
  );
}
