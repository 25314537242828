import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Grid, Typography, Button, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import DateSelectionDialog from "./dateSelectionDialog";
import { selectCollection } from "../../../redux/reducers/cart";

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: "flex",
    flexDirection: "row",
  },
  openCalendarBtn: {
    borderColor: "#fd7762 !important",
    color: "#fd7762 !important",
    borderRadius: "20px !important",
    "&:hover": {
      borderColor: "#fd7762 !important",
      color: "#fd7762 !important",
      backgroundColor: "#fdddd8 !important",
    },
  },
  textStyle: {
    ...theme.textStyle,
    color: "#fd7762 !important",
    fontWeight: "bold !important",
  },
}));

const DateSelection = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { date, timeslot } = useSelector(selectCollection);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container sx={{ mb: 3 }}>
      <Grid item xs={12} sx={{ mb: 3 }}>
        <Box className={classes.titleWrapper}>
          <CalendarMonthOutlinedIcon
            style={{ color: "#fd7762", marginRight: "10px" }}
          />
          <Typography className={classes.textStyle}>Select Date</Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex" }}>
          {date && timeslot && (
            <Box
              sx={{
                width: "150px !important",
                borderRadius: "20px !important",
                border: "1px solid #e4e4e7 !important",
                padding: "15px !important",
                mr: 2,
                textAlign: "center !important",
              }}>
              <Typography>{`${date} at ${timeslot}`}</Typography>
            </Box>
          )}

          <Button
            variant="outlined"
            onClick={handleClickOpen}
            className={classes.openCalendarBtn}
            sx={{}}>
            Open Calendar
          </Button>
        </Box>
        <DateSelectionDialog open={open} onClose={handleClose} />
      </Grid>
    </Grid>
  );
};

export default DateSelection;
