import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  CardMedia,
  Container,
} from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { chosenGrazeBoxSelected } from "../../redux/reducers/grazeBox";
import MainActionButton from "../../components/buttons/mainActionButton";
import charcuterieBg from "../../assets/images/grazeBoxes/charcuterie_bg.jpg";
import grazeBoxLarge from "../../assets/images/grazeBoxes/graze_box_large.jpg";
import SavingsIcon from "@mui/icons-material/AttachMoney";
import DeliveryIcon from "@mui/icons-material/LocalShipping";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import StarIcon from "@mui/icons-material/Star";
import verafinLogo from "../../assets/images/grazeBoxes/verafin-logo.png";
import cenovusLogo from "../../assets/images/grazeBoxes/cenovusLogo.png";
import exxonMobilLogo from "../../assets/images/grazeBoxes/exxonmobilLogo.png";
import hudsonAndRexLogo from "../../assets/images/grazeBoxes/hudsonAndRexLogo.jpeg";
import propelLogo from "../../assets/images/grazeBoxes/propelLogo.png";
import oneill from "../../assets/images/grazeBoxes/oneill_auto_group.png";
import Header from "../../components/header";
import NewTopAppBar from "../../components/newAppBar";
import Footer from "../../components/footer";

const useStyles = makeStyles((theme) => ({
  heroBackground: {
    textAlign: "center",
    backgroundImage: `url(${charcuterieBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    color: "#fff",
    padding: 8,
    borderRadius: 2,
    height: "600px",
    position: "relative",
  },
  headingWrapper: {
    width: "80%",
    backgroundColor: " #ffe6e6",
    padding: "2rem 1rem",
    color: "#000",
    borderRadius: "20px",
    margin: "0",
    position: "absolute",
    top: "50%",
    left: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: " translate(-50%, -50%)",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      padding: "3rem",
    },
  },
  heading: {
    fontSize: "24px !important",
    fontWeight: "bold !important",
    marginBottom: "1rem !important",
    fontFamily: "Moul !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "48px !important",
    },
  },
  subheading: {
    fontSize: "16px !important",
    marginBottom: "1rem !important",
    fontFamily: "Inter !important",
    [theme.breakpoints.up("sm")]: {
      fontSize: "24px !important",
    },
  },
  mainBtn: {
    ...theme.containedButtonDark,
    padding: "10px 20px",
    marginTop: "5px",
    textTransform: "none",
    fontFamily: "Inter",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
      padding: "14px 50px",
    },
  },
  orderNow: {
    ...theme.containedButtonDark,
    padding: "10px 20px",
    marginTop: "5px",
    textTransform: "none",
    fontFamily: "Inter",
    width: "100%",
    fontSize: "18px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
  },
}));

const features = [
  {
    icon: <SavingsIcon fontSize="large" />,
    title: "Bundle and Save",
    description: "Get 2 or more boxes and save 10%.",
  },
  {
    icon: <DeliveryIcon fontSize="large" />,
    title: "Local Delivery Available.",
    description:
      "Unable to pick up? We offer delivery within St. John's and nearby cities",
    note: "",
  },
  {
    icon: <CardGiftcardIcon fontSize="large" />,
    title: "Gift Packaging",
    description: "Special packaging available to make it the perfect gift!",
  },
];

const companies = [
  {
    name: "Verafin",
    logo: verafinLogo,
  },
  {
    name: "Cenovus",
    logo: cenovusLogo,
  },
  {
    name: "ExxonMobil",
    logo: exxonMobilLogo,
  },
  {
    name: "Hudson & Rex",
    logo: hudsonAndRexLogo,
  },
  {
    name: "Propel",
    logo: propelLogo,
  },
  {
    name: "O'Neill Group",
    logo: oneill,
  },
];

const testimonials = [
  {
    name: "Elen A.",
    rating: 5,
    text: "Wow, just wow! The graze box was an absolute masterpiece. Every item felt like it was handpicked for its freshness and flavor. It wasn’t just a feast for the palate but also for the eyes—so beautifully arranged! My guests couldn’t stop talking about it, and it truly elevated our gathering. I can’t wait to order again for my next event. Highly recommend!",
  },
  {
    name: "Heather M.",
    rating: 5,
    text: "The level of care and detail that went into our charcuterie board was unmatched. From the perfectly paired cheeses and cured meats to the delicious little surprises like gourmet dips and chocolates, everything was flawless. It arrived on time and fresh, and the presentation was so stunning we didn’t want to touch it. Definitely my go-to for celebrations!",
  },
  {
    name: "Ian D.",
    rating: 5,
    text: "Our office event was a hit thanks to this stunning grazing board! Not only was it delicious, but it was also a conversation starter. The variety was incredible—something for everyone, from savory to sweet. It made the event feel so special and elevated. Several colleagues have already asked where I got it from. Can’t recommend this enough!",
  },
];

const GrazeBoxes = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const targetSectionRef = useRef(null);

  const scrollToSection = () => {
    targetSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [grazeBoxProducts, setGrazeBoxProducts] = useState([]);
  const [dataIsReady, setDataIsReady] = useState(false);

  const handleOrderButtonClick = (grazeBoxDoc) => {
    dispatch(chosenGrazeBoxSelected(grazeBoxDoc));
    navigate("/graze-boxes/details");
  };

  useEffect(() => {
    async function fetchGrazeBoxes() {
      const res = await axios.get("/api/app/data/grazeBoxes");
      setGrazeBoxProducts(res.data);
      setDataIsReady(true);
    }

    fetchGrazeBoxes();
  }, []);

  return (
    <Box>
      <Header></Header>
      <NewTopAppBar></NewTopAppBar>
      {/* Hero Section */}
      <Box className={classes.heroBackground}>
        <Box className={classes.headingWrapper}>
          <Typography className={classes.heading}>
            Elevate Your Events with Our Grazing Boxes
          </Typography>
          <Typography className={classes.subheading}>
            Curated for freshness, taste, and beauty.
          </Typography>
          <MainActionButton
            clickEvent={scrollToSection}
            testId={"signup-btn"}
            styleObj={classes.orderNow}>
            Shop Now
          </MainActionButton>
        </Box>
      </Box>

      <Box sx={{ backgroundColor: "#f1f1f1", padding: 4, textAlign: "center" }}>
        {/* Header */}
        <Typography
          variant="h4"
          sx={{ fontWeight: "bold", marginBottom: 3, fontFamily: "Moul" }}>
          Trusted by Companies like
        </Typography>

        {/* Company Logos */}
        <Grid container spacing={4} justifyContent="center">
          {companies.map((company, index) => (
            <Grid item xs={6} sm={4} md={2} key={index}>
              <Box
                component="img"
                src={company.logo}
                alt={company.name}
                sx={{
                  width: "150px", // Set a fixed width
                  height: "80px", // Set a fixed height
                  objectFit: "contain", // Ensures logos maintain aspect ratio
                  margin: "0 auto", // Centers the logo
                  display: "block", // Removes inline spacing issues
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      {/* Products Section */}
      <Box sx={{ marginTop: 6, marginBottom: 6 }} ref={targetSectionRef}>
        <Typography
          variant="h4"
          gutterBottom
          textAlign="center"
          sx={{ fontFamily: "Moul" }}>
          Shop By Style
        </Typography>
        <Container maxWidth="xl">
          {dataIsReady ? (
            <Grid container spacing={4}>
              {grazeBoxProducts.map((product) => (
                <Grid item xs={12} sm={6} md={4} key={product.id}>
                  <Card sx={{ height: "100%" }}>
                    <CardMedia
                      component="img"
                      height="350"
                      image={product.coverImage}
                      alt="cake image"
                    />
                    <CardContent>
                      <Typography
                        variant="h5"
                        gutterBottom
                        sx={{ fontFamily: "Inter" }}>
                        {product.name}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        gutterBottom
                        sx={{ fontFamily: "Inter" }}>
                        based on {product.shortInfo}
                      </Typography>
                      <Typography variant="body1" sx={{ fontFamily: "Inter" }}>
                        {product.servings}
                      </Typography>
                    </CardContent>
                    <CardActions
                      sx={{
                        alignItems: "center",
                        justifyContent: "center",
                        paddingBottom: 2,
                      }}>
                      <MainActionButton
                        clickEvent={() => handleOrderButtonClick(product)}
                        testId={""}
                        styleObj={classes.orderNow}>
                        Customize and Order
                      </MainActionButton>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          ) : (
            <Box>Loading...</Box>
          )}
        </Container>
      </Box>

      <Box
        sx={{
          backgroundColor: "#121212",
          color: "white",
          padding: 4,
          textAlign: "center",
        }}>
        <Grid container spacing={4}>
          {features.map((feature, index) => (
            <Grid item xs={12} sm={4} key={index}>
              <Box>
                {feature.icon}
                <Typography
                  variant="h6"
                  sx={{ marginTop: 2, fontWeight: "bold" }}>
                  {feature.title}
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  {feature.description}
                </Typography>
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  {feature.note}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Container maxWidth="xl">
        <Box sx={{ paddingTop: 8, paddingBottom: 8 }}>
          {/* Section Title */}
          <Typography
            variant="h4"
            sx={{
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: 4,
              fontFamily: "Moul",
            }}>
            Celebrated for gatherings, gifts, and corporate events.
          </Typography>

          {/* Testimonials */}

          <Grid container spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ height: "100%", boxShadow: 3 }}>
                  <CardContent>
                    {/* Star Rating */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: 1,
                      }}>
                      {[...Array(testimonial.rating)].map((_, i) => (
                        <StarIcon key={i} sx={{ color: "#FFD700" }} />
                      ))}
                    </Box>
                    {/* Name */}
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: "bold", marginBottom: 2 }}>
                      {testimonial.name}
                    </Typography>
                    {/* Text */}
                    <Typography variant="body1">{testimonial.text}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
      <Footer></Footer>
    </Box>
  );
};

export default GrazeBoxes;
